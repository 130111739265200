/* src/components/Header.module.css */
header {
    background-color: #444;
    color: white;
    padding: 1rem;
  }
  nav a {
    color: white;
    margin: 0 1rem;
    text-decoration: none;
  }
  