/* src/components/Header.css */
.header {
    background-color: #333;
    color: white;
    padding: 1rem;
  }
  
  /* .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  } */
  
  .logo-link {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height:70px; /* Adjust the height as needed */
    width: auto; /* Maintain aspect ratio */
    display: flex;
  }
  
  /* .nav-links a {
    color: white;
    margin: 0 1rem;
    text-decoration: none;
  }
  
  .nav-links a:hover {
    text-decoration: underline;
  }
   */